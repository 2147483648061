import { ServiceLayer } from './service-rest-layers.enum';

export const serviceFeatureInstDistVue: string = 'https://services5.arcgis.com/jD5Z6QYavsBfnE6G/arcgis/rest/services/A396_Capture_Inst_Dist_Vue/FeatureServer/';
export const serviceFeatureInstDist: string = 'https://services5.arcgis.com/jD5Z6QYavsBfnE6G/arcgis/rest/services/A396_Capture_Inst_Dist_20241128/FeatureServer/';
export const serviceFeatureAiresProtegees: string = 'https://services5.arcgis.com/jD5Z6QYavsBfnE6G/arcgis/rest/services/Aires_protegees_vue/FeatureServer/';

export const serviceUrl = {
    POSTE: serviceFeatureInstDistVue + ServiceLayer.POSTE,
    POTEAU: serviceFeatureInstDistVue + ServiceLayer.POTEAU,
    LCLCL_AERIEN: serviceFeatureInstDistVue + ServiceLayer.LCLCL_AERIEN,
    LCLCL_SOUTERRAIN: serviceFeatureInstDistVue + ServiceLayer.LCLCL_SOUTERRAIN,
    CONDUCTEUR_MT_AERIEN: serviceFeatureInstDistVue + ServiceLayer.CONDUCTEUR_MT_AERIEN,
    CONDUCTEUR_MT_SOUTERRAIN: serviceFeatureInstDistVue + ServiceLayer.CONDUCTEUR_MT_SOUTERRAIN,
    PARC_DE_POTEAU_HQ: serviceFeatureInstDistVue + ServiceLayer.PARC_DE_POTEAU_HQ,
    PARC_DE_POTEAU_AUTRE: serviceFeatureInstDistVue + ServiceLayer.PARC_DE_POTEAU_AUTRE,
    NATIONS: serviceFeatureInstDist + ServiceLayer.NATIONS,
    RAINETTES: serviceFeatureAiresProtegees + ServiceLayer.RAINETTES,
};
