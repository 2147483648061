export enum ServiceLayer {
    POSTE = 0,
    POTEAU = 1,
    LCLCL_AERIEN = 2,
    LCLCL_SOUTERRAIN = 3,
    CONDUCTEUR_MT_AERIEN = 4,
    CONDUCTEUR_MT_SOUTERRAIN = 5,
    PARC_DE_POTEAU_HQ = 6,
    PARC_DE_POTEAU_AUTRE = 7,
    NATIONS = 14,
    RAINETTES = 21,
}
